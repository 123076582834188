var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vx-card", { staticClass: "overflow-hidden" }, [
    _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
      _c(
        "div",
        {
          staticClass: "p-6",
          class: {
            "flex justify-between flex-row-reverse items-center": _vm.iconRight,
            "text-center": !_vm.iconRight && _vm.hideChart,
            "pb-0": !_vm.hideChart,
          },
        },
        [
          _c("feather-icon", {
            staticClass: "p-3 inline-flex rounded-full",
            class: [`text-${_vm.color}`, { "mb-4": !_vm.iconRight }],
            style: {
              background: `rgba(var(--vs-${_vm.color}),.15)`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            attrs: {
              icon: _vm.icon,
              svgClasses: _vm.itemsSize ? _vm.itemsSize.icon : "w-6 h-6",
            },
          }),
          _vm._t("default"),
          _c("div", { staticClass: "truncate" }, [
            _c(
              "h2",
              {
                staticClass: "mb-1 font-bold",
                class: `text-${_vm.colorStatistic}`,
                style: {
                  fontSize: _vm.itemsSize
                    ? _vm.itemsSize.statistic || "2em"
                    : "2em",
                },
              },
              [
                _vm._v(
                  "\n                  " +
                    _vm._s(_vm.statistic) +
                    "\n                "
                ),
              ]
            ),
            _c(
              "span",
              {
                style: {
                  fontSize: _vm.itemsSize
                    ? _vm.itemsSize.title || "1em"
                    : "1em",
                },
              },
              [_vm._v(_vm._s(_vm.statisticTitle))]
            ),
          ]),
        ],
        2
      ),
      !_vm.hideChart
        ? _c(
            "div",
            { staticClass: "line-area-chart" },
            [
              _c("vue-apex-charts", {
                ref: "apexChart",
                attrs: {
                  type: _vm.type,
                  height: "100",
                  width: "100%",
                  options: _vm.chartOptions,
                  series: _vm.chartData,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }